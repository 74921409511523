<template>
  <vx-card title="Debit Note">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-3">
        <vs-divider />
        <span class="font-bold">Supplier Returns</span>
        <div style="max-height: 250px; overflow: auto">
          <vs-table stripe :data="responseData">
            <template slot="thead">
              <vs-th> SR Number </vs-th>
              <vs-th> SR Type </vs-th>
              <vs-th> PO Number Reference </vs-th>
              <vs-th> GRR Code </vs-th>
              <vs-th> Suppplier </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  {{ tr.supplier_returns.code }}
                </vs-td>

                <vs-td>
                  {{ tr.supplier_returns.ref_status }}
                </vs-td>

                <vs-td>
                  {{ tr.purchase_order ? tr.purchase_order.Code : "" }}
                </vs-td>

                <vs-td>
                  {{ tr.supplier_returns.grr_code }}
                </vs-td>

                <vs-td>
                  Code : {{ tr.supplier_returns.supplier_code }} <br />
                  Name : {{ tr.supplier_returns.supplier_name }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <vs-divider />
      <form-header
        @updateModel="updateHeaderModels"
        @updateFile="updateFile"
        :isSubmit="isSubmit"
        :settlementMethodOptions="settlementMethodOptions"
        :settlementMethod="supplierSettlement"
        :poDate="
          responseData.length > 0 &&
          responseData[0].purchase_order !== undefined
            ? responseData[0].purchase_order.Date
            : ''
        "
        :taxInvoiceReferenceOptions="taxInvoiceReferenceOptions"
      />
      <vs-divider />
      <form-details
        v-if="this.supplierReturnDetails.length > 0"
        :supplierReturnDetails="supplierReturnDetails"
        @updateModel="updateDetailModels"
        @updateChecked="updateChecked"
        :isSubmit="isSubmit"
        :srTaxes="srTaxes"
        :isPoReturn="isPoReturn"
      />
      <vs-divider />
      <div class="flex gap-3">
        <vs-button @click="submit">Create</vs-button>
        <vs-button @click="navigateBack" color="danger">Close</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import form_header from "./form_header.vue";
import form_details from "./form_details.vue";
import moment from "moment";

export default {
  components: {
    "form-header": form_header,
    "form-details": form_details,
  },
  data() {
    return {
      responseData: [],
      supplierReturnDetails: [],
      settlementMethodOptions: [],
      supplierSettlement: null,
      sr_ids: [],
      taxInvoiceReferenceOptions: [],
      params: {
        search: "",
        length: 20,
        page: 1,
        order: "desc",
        sort: "id",
      },
      formHeaderModels: {
        cnNumber: "",
        cnDate: "",
        trNumber: "",
        trDate: "",
        settlementMethod: "",
        dnNumber: "",
        postingDate: "",
        note: "",
        taxInvoiceReference: {},
        taxInvoiceReferenceCode: "",
        taxInvoiceReferenceRefDate: "",
      },
      formDetailModels: [],
      isSubmit: false,
      file: null,
      checked: [],
      srTaxes: [],
      isPoReturn: false,
    };
  },
  mounted() {
    const query = atob(this.$router.currentRoute.query.sr_ids);
    console.log(query);
    this.sr_ids = query ? JSON.parse(query) : [];
    console.log(this.sr_ids, "decoded");
    this.getDetail();
  },
  methods: {
    async getTaxInvoiceReference() {
      this.$vs.loading();
      const params = {
        supplier_id: this.responseData[0].supplier_returns.supplier_id,
        purchase_order_id:
          this.responseData[0].purchase_order &&
          this.responseData[0].purchase_order.ID
            ? [this.responseData[0].purchase_order.ID]
            : null,
      };
      if (this.responseData[0].supplier_returns.ref_status === "with ref") {
        params.purchase_order_id = this.responseData.map(
          (data) => data.purchase_order.ID
        );
      }
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/return/tax-invoice-reference",
          {
            params,
          }
        );
        this.$vs.loading.close();
        if (response.code > 299) {
          this.$vs.notify({
            title: "Error",
            text: response.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
        this.taxInvoiceReferenceOptions = response.data ? response.data : [];
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        throw e;
      }
    },
    async getSupplierReturns(params) {
      this.$vs.loading();
      params = {
        ...params,
        pr_status: 11,
        sr_status: 8,
        ids: this.sr_ids,
        is_edit: false,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return",
          {
            params,
          }
        );
        this.responseData = response.data.records;
        this.supplierSettlement = response.data.records[0].supplier_settlement;
        this.srTaxes = response.data.records.map((data) => {
          return data.tax;
        });
        this.isPoReturn = response.data.records[0].purchase_order !== undefined;
        console.log("stlmnt", this.supplierSettlement);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getSupplierReturnDetails() {
      this.$vs.loading();
      const pr_ids = [];
      const sr_ids = [];

      this.responseData.forEach((data) => {
        if (data.supplier_returns.ref_status === "with ref") {
          pr_ids.push(data.supplier_returns.id);
        } else {
          sr_ids.push(data.supplier_returns.id);
        }
      });
      const params = {
        pr_ids,
        sr_ids,
        is_edit: false,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return/detail",
          {
            params,
          }
        );
        this.supplierReturnDetails = response.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },

    async getSupplierSettlementMethods() {
      this.$vs.loading();
      try {
        const response = await this.$http.get(
          "/api/v1/supplier-settlement-method"
        );
        this.settlementMethodOptions = response.data;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async getDetail() {
      try {
        await this.getSupplierReturns(this.params);
        await this.getSupplierSettlementMethods();
        await this.getSupplierReturnDetails();
        await this.getTaxInvoiceReference();
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    updateDetailModels(value) {
      this.formDetailModels = value;
    },
    updateHeaderModels(value) {
      this.formHeaderModels = value;
    },
    updateFile(value) {
      this.file = value;
    },
    updateChecked(value) {
      this.checked = value;
    },
    resetIsSubmit() {
      this.isSubmit = false;
    },
    validate() {
      if (this.checked.length === 0) {
        this.$vs.notify({
          title: "Debit Note",
          text: "Please select at least one item",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return false;
      }

      let isValid = true;
      // if (this.formHeaderModels.cnNumber === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Supplier credit note number is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      // if (this.formHeaderModels.cnDate === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Supplier credit note Date is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      // if (this.formHeaderModels.trNumber === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Tax return number is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      if (
        !this.formHeaderModels.trDate ||
        this.formHeaderModels.trDate === ""
      ) {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Tax return Date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (this.formHeaderModels.settlementMethod === "") {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Settlement method is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (this.formHeaderModels.dnNumber === "") {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Debit note number is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (
        !this.formHeaderModels.taxInvoiceReference ||
        Object.keys(this.formHeaderModels.taxInvoiceReference).length === 0
      ) {
        if (this.formHeaderModels.taxInvoiceReferenceCode === "") {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Tax invoice reference is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return isValid;
        }
      }

      if (
        !this.formHeaderModels.taxInvoiceReferenceRefDate ||
        this.formHeaderModels.taxInvoiceReferenceRefDate === ""
      ) {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Tax invoice reference date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      if (
        !this.formHeaderModels.postingDate ||
        this.formHeaderModels.postingDate === ""
      ) {
        isValid = false;
        this.$vs.notify({
          title: "Debit Note",
          text: "Posting Date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return isValid;
      }

      // if (this.formDetailModels.taxRate === 0) {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Tax rate are required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      // if (this.formDetailModels.refAPInvoice === "") {
      //   isValid = false;
      //   this.$vs.notify({
      //     title: "Debit Note",
      //     text: "Reference AP Invoice is required",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //   });
      //   return isValid;
      // }

      console.log("len", this.formDetailModels.details.length);
      for (let i = 0; i < this.formDetailModels.details.length; i++) {
        const qty = parseInt(
          this.formDetailModels.details[i].qty.replace(/,/g, "")
        );

        if (isNaN(qty)) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Quantity is not valid",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        if (qty === 0) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Quantity amount must be greater than 0",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        const sr = this.supplierReturnDetails.find(
          (data) => data.id === this.formDetailModels.details[i].id
        );
        const maxQty = parseInt(sr.remaining_qty);

        if (qty > maxQty) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Quantity amount is over limit",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }
        // if (
        //   this.formDetailModels.details[i].qty === "" ||
        //   this.formDetailModels.details[i].qty === "0"
        // ) {
        //   isValid = false;
        //   this.$vs.notify({
        //     title: "Debit Note",
        //     text: "Quantity amount must be greater than 0",
        //     color: "danger",
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-alert-circle",
        //   });
        //   break;
        // }

        if (
          this.formDetailModels.details[i].total === "" ||
          this.formDetailModels.details[i].total === "0"
        ) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Debit note total is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }

        if (
          this.formDetailModels.details[i].value === "" ||
          this.formDetailModels.details[i].value === "0"
        ) {
          isValid = false;
          this.$vs.notify({
            title: "Debit Note",
            text: "Debit note value is requireds",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          break;
        }
      }
      return isValid;
    },
    navigateBack() {
      this.$router.push({
        name: "dn-with-or-without-po-ref",
      });
    },
    validateTotal(aggregate, remaining_tax_invoice, tax_invoice_reference) {
      if (
        !tax_invoice_reference ||
        Object.keys(tax_invoice_reference).length === 0
      ) {
        return true;
      }

      if (aggregate.total > remaining_tax_invoice) {
        this.$vs.notify({
          title: "Debit Note",
          text: "Debit note total value cannot be greater than remaining of tax invoice reference",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
          time: 4000,
        });
        return false;
      }
      return true;
    },
    submit() {
      this.isSubmit = true;

      this.$nextTick(() => {
        console.log(this.formDetailModels);
        console.log(this.formHeaderModels);
        this.isSubmit = false;

        const aggregate = this.formDetailModels.details.reduce(
          (acc, detail) => {
            acc.qty =
              parseFloat(acc.qty) + parseFloat(detail.qty.replace(/,/g, ""));
            acc.value =
              parseFloat(acc.value) +
              parseFloat(detail.value.replace(/,/g, ""));
            acc.tax =
              parseFloat(acc.tax) + parseFloat(detail.tax.replace(/,/g, ""));
            acc.total =
              parseFloat(acc.total) +
              parseFloat(detail.total.replace(/,/g, ""));

            return acc;
          },
          {
            qty: 0,
            value: 0,
            tax: 0,
            total: 0,
          }
        );

        if (!this.validate()) {
          return;
        }

        if (
          !this.validateTotal(
            aggregate,
            this.formHeaderModels.taxInvoiceReference &&
              Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0
              ? this.formHeaderModels.taxInvoiceReference.remaining
              : 0,
            this.formHeaderModels.taxInvoiceReference
          )
        ) {
          return;
        }
        this.$vs.loading();
        const header = {
          ...this.formHeaderModels,
        };

        header.cnDate = header.cnDate
          ? moment(header.cnDate).format("YYYY-MM-DD")
          : "";
        header.trDate = header.trDate
          ? moment(header.trDate).format("YYYY-MM-DD")
          : "";
        header.postingDate = moment(header.postingDate).format("YYYY-MM-DD");
        header.taxInvoiceReferenceRefDate = header.taxInvoiceReferenceRefDate
          ? moment(header.taxInvoiceReferenceRefDate).format("YYYY-MM-DD")
          : "";

        let srCodes = this.responseData.map(
          (data) => data.supplier_returns.code
        );
        const temp = new Set(srCodes);
        srCodes = Array.from(temp);
        let srCode = "";

        if (srCodes.length > 1) {
          srCode = srCodes.join(";");
        } else {
          srCode = srCodes[0];
        }

        header.srCode = srCode;
        header.aggregate = aggregate;
        header.supplierID = this.responseData[0].supplier_returns.supplier_id;
        header.warehouseID = this.responseData[0].supplier_returns.warehouse_id;
        const pr_ids = [];
        const sr_ids = [];

        if (
          this.formHeaderModels.taxInvoiceReference &&
          Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0
        ) {
          header.taxInvoiceReference = {
            id: this.formHeaderModels.taxInvoiceReference.id,
            value: header.aggregate.total,
          };
        }

        this.responseData.forEach((data) => {
          if (data.supplier_returns.ref_status === "with ref") {
            pr_ids.push(data.supplier_returns.id);
          } else {
            sr_ids.push(data.supplier_returns.id);
          }
        });

        header.pr_ids = pr_ids;
        header.sr_ids = sr_ids;

        const details = [];

        for (let i = 0; i < this.formDetailModels.details.length; i++) {
          const line = this.supplierReturnDetails.find(
            (data) => data.id === this.formDetailModels.details[i].id
          );
          const detail = {
            id: line.id,
            qty: parseFloat(
              this.formDetailModels.details[i].qty.replace(/,/g, "")
            ),
            total: parseFloat(
              this.formDetailModels.details[i].total.replace(/,/g, "")
            ),
            value: parseFloat(
              this.formDetailModels.details[i].value.replace(/,/g, "")
            ),
            tax: parseFloat(
              this.formDetailModels.details[i].tax.replace(/,/g, "")
            ),
            item_name: line.item_name,
            grr_code: line.grr_code,
          };

          if (line.purchase_return_id) {
            detail.purchase_return_id = line.purchase_return_id;
          } else {
            detail.supplier_return_id = line.supplier_return_id;
          }
          details.push(detail);
        }

        const detail_info = {
          ref_ap_invoice: this.formDetailModels.refAPInvoice,
          tax_rate: this.formDetailModels.taxRate,
          tax_id: this.formDetailModels.taxID,
          details,
        };

        const data = {
          header,
          detail_info,
        };

        console.log("data", data);

        const form = new FormData();
        form.append("data", JSON.stringify(data));

        if (this.file) {
          form.append("file", this.file);
        }

        console.log("final", form);
        this.$http
          .post("/api/v1/debit-note/return", form)
          .then((r) => {
            this.$vs.loading.close();

            if (r.code < 299) {
              this.$vs.notify({
                title: "Debit Note",
                text: "Debit note created successfully",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.$router.push({
                name: "dn-with-or-without-po-ref",
              });
            } else {
              this.$vs.notify({
                title: "Debit Note",
                text: r.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
                time: 5000,
              });

              if (r.data && r.data.is_locked) {
                this.$router.push({
                  name: "dn-with-or-without-po-ref",
                });
              }
            }
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          });
      });
      // this.remaining_value = dn;
    },
  },
};
</script>
